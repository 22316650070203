import { createStore, applyMiddleware } from 'redux';
import rootReducer from './shared/reducers';
import thunk from 'redux-thunk';
import middleware from './shared/middleware/middleware';

export default function configureStore(initialState) {
  const middlewares = [
    middleware,
    thunk,
  ];

  let store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));
  
  return store;
}