import React, { useState, useEffect, useContext ,useRef, useDispatch,Fragment} from "react";
import { API, graphqlOperation } from "aws-amplify";
import { connect } from "react-redux";
import AuthContext from '../shared/utils/authContext';
import { Dialog, Transition } from '@headlessui/react'
import {
  listStatusChangeRequests , leadsByAssignedToFollowUpAt
} from '../shared/graphql/queries';
import {
  createStatusChangeRequest
} from '../shared/graphql/mutations';
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  TextField
} from "@material-ui/core";
import SimpleSnackbar from "../components/shared/SimpleSnackbar";
import {
  getUserNameFromUserList, getRequestTypeText, getStatusText, getLeadSourceText, getCaption
} from '../shared/utils'

export default function LeadsDetailsModal(props) {
  
  //const dispatch = useDispatch();
  const cancelButtonRef = useRef(null)
  console.log(props);
  const userInfo = useContext(AuthContext);
  const [inProgress, setInProgress] = useState(false);
  const [rejectInProgress, setRejectInProgress] = useState(false);
  const [failed, setFailed] = useState(false);
  console.log(userInfo);
   /* const updateHoldRequestStatus = () => {
      setInProgress(true);
      API.graphql(graphqlOperation(
        updateStatusChangeRequest, {
          input: {
            clientId: props.request.clientId,
            projectId: props.request.projectId,
            requestId: props.request.requestId,
            status: "ACCEPTED"
          }
      })).then((response) => {
        console.log("updateStatusChangeRequest", response.data.updateStatusChangeRequest);
        updatePropertyStatus(response.data.updateStatusChangeRequest);
      }).catch((err) => {
        //setInProgress(false);
        setFailed(true);
        console.warn(err);
      });

    } */

    /* const updateHoldRejectRequestStatus = () => {
      setRejectInProgress(true);
      API.graphql(graphqlOperation(
        updateStatusChangeRequest, {
          input: {
            clientId: props.request.clientId,
            projectId: props.request.projectId,
            requestId: props.request.requestId,
            status: "REJECTED"
          }
      })).then((response) => {
        console.log("updateStatusChangeRequest", response.data.updateStatusChangeRequest);
        let updatedRequest = response.data.updateStatusChangeRequest;
        dispatch(updateRequestAction(updatedRequest.requestId, updatedRequest));
        setRejectInProgress(false);
        props.setLeadsOpen(false);
       // setLeadsOpen={setLeadsOpen}

      }).catch((err) => {
        //setInProgress(false);
        setFailed(true);
        console.warn(err);
      });

    }

  const updatePropertyStatus = (updatedRequest)=>{

    API.graphql(graphqlOperation(
      updateProperty, {
        input: {
          clientId: props.request.clientId,
          projectId: props.request.projectId,
          propertyId: props.request.propertyId,
          status: "ON_HOLD",
          assignedTo: userInfo.userId
        }
    })).then((response) => {
      setFailed(false);
      console.log("updateProperty", response);
      // props.closeDialog();
      props.setLeadsOpen(false);
      dispatch(updateRequestAction(updatedRequest.requestId, updatedRequest))
      setInProgress(false);
      
    }).catch((err) => {
      //setInProgress(false);
      setFailed(true);
      console.warn(err);
    });
  } */
  
  return (
    <Transition.Root show={props.leadsOpen} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden" open={props.leadsOpen} onClose={props.setLeadsOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            initialFocus={cancelButtonRef}
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pt-16 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button>
                      {/* <span className="sr-only">Close panel</span> */}
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  {/* <div className="px-2 sm:px-6"><Dialog.Title className="text-2xl font-medium text-gray-900">Request to Hold</Dialog.Title></div> */}
                 
                  <div onClick={() => props.setLeadsOpen(false)} style={{cursor: 'pointer'}}  className="absolute top-6 right-6 hover:bg-red-500">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                    {/* self-end px-4 sm:px-6 */}
                  </div>
                 
                  <div className="pt-8 pl-4">

                      <div className="flex flex-row justify-between">
                      <div className="font-semibold text-xl text-gray-600 mt-2">{props.leadData.name}</div>
                      <div className="flex-end pr-6 mt-2 hover:text-purple-700">{props.getLeadStatustype(props.leadData.status)}</div>
                      </div>
                      <div className="text-sm">
                      <div className="text-gray-600 mt-2 flex-center">{getCaption(props.leadData)}</div>
                      <div className="text-gray-600 mt-2 flex-center">{getLeadSourceText(props.leadData.source)}</div>
                      <div className="text-gray-600 mt-2 flex-center">{props.leadData.message}</div>
                      </div>
                      <div>
                      <div className="text-gray-600 bg-gray-100 mt-2 ">Phone Number</div>
                      <div className="flex justify-between">
                      <div className="text-gray-600 text-sm mt-2 ">{props.leadData.phoneNumber}</div>
                      <div className="flex space-x-4">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 mt-2 w-5 " viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                      <path d="M16.707 3.293a1 1 0 010 1.414L15.414 6l1.293 1.293a1 1 0 01-1.414 1.414L14 7.414l-1.293 1.293a1 1 0 11-1.414-1.414L12.586 6l-1.293-1.293a1 1 0 011.414-1.414L14 4.586l1.293-1.293a1 1 0 011.414 0z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                     </svg>
                      </div>
                      </div>
                      </div>
                      <div>
                      <div className="text-gray-600 bg-gray-100 mt-2 ">Email</div>
                      <div className="flex justify-between">
                      <div className="text-gray-600 text-sm mt-2 ">{props.leadData.email}</div>
                      <div className="pr-4">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg></div>
                      </div>
                      </div>
                      <div>
                      <div className="text-gray-600 bg-gray-100 mt-2 ">Message</div>
                      <div className="text-gray-600 text-sm mt-2 ">{props.leadData.message}</div>
                      </div>

                      <div>
                      <div className="text-gray-600 bg-gray-100 mt-2 ">Status</div>
                      
                      <div className="mt-2 flex space-x-6">
                      <select
                        name="Follow Up"
                        className="block mt-1 text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                      <option value="Follow Up"> Follow Up </option>
                      <option value="ON_HOLD"> On Hold </option>
                      <option value="SOLD"> Sold </option>
                      </select>
                      <div className="text-gray-600 text-sm mt-3">Follow Up On:</div>
                      <select
                        name="Follow Up"
                        className="block mt-1 text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                      <option value="Follow Up"> {props.leadData.followUpAt} </option>
                      <option value="ON_HOLD"> On Hold </option>
                      <option value="SOLD"> Sold </option>
                      </select>
                    </div>
                       
                    <div>
                    <div className="text-gray-600 bg-gray-100 mt-2 ">Project Name</div>
                    <select
                        name="Follow Up"
                        className="block mt-3 text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                      <option value="Follow Up"> Project1 </option>
                      <option value="ON_HOLD"> On Hold </option>
                      <option value="SOLD"> Sold </option>
                      </select>
                    </div>
                    <div>
                    <div className="text-gray-600 bg-gray-100 mt-2 ">Assigned To User</div>
                    <select
                        name="Follow Up"
                        className="block mt-3 text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                      <option value="Follow Up"> Demo Account </option>
                      <option value="ON_HOLD"> On Hold </option>
                      <option value="SOLD"> Sold </option>
                      </select>
                    </div>
                    <div>
                    <div className="text-gray-600 bg-gray-100 mt-2 ">Activity Timeline</div>
                    <div className="flex mt-2 text-xs font-semibold py-2 px-2 uppercase rounded text-purple-600 bg-purple-200 w-max last:mr-0 mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                  </svg><div className="mx-1">ADD NOTE</div>
                  </div>
                    </div>

                          {/* Timeline code */}
                      
<div class="container">
          <div
            class="flex flex-col md:grid grid-cols-12  text-blue-50"
          >
            
            {/* first row */}
            <div class="flex flex-row-reverse md:contents">
              <div
                class=" col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto"
              >
                <h3 class="font-normal text-gray-700 text-base mb-1">28-03-2021</h3>
              </div>
              <div class="flex md:contents">
              <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                {/* stick */}
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-purple-800 pointer-events-none"></div>
                </div>
                {/* stick circle */}
                <div
                  class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-purple-500 shadow"
                ></div>
              </div>
              {/* col-end-13 */}
              <div
                class="col-start-6 col-span-full p-4 rounded-xl my-4 mr-auto" 
              >
                <h3 class="font-normal text-base text-gray-700 mb-1">Status changed to follow up</h3>
              </div>
            </div>

            {/* 2nd row */}
            </div>
            <div class="flex flex-row-reverse md:contents">
              <div
                class=" col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto"
              >
                <h3 class="font-normal text-gray-700 text-base mb-1">28-03-2021</h3>
              </div>
              <div class="flex md:contents">
              <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                {/* stick */}
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-purple-800 pointer-events-none"></div>
                </div>
                {/* stick circle */}
                <div
                  class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-purple-500 shadow"
                ></div>
              </div>
              {/* col-end-13 */}
              <div
                class="col-start-6 col-span-full p-4 rounded-xl my-4 mr-auto" 
              >
                <h3 class="font-normal text-base text-gray-700 mb-1">Status changed to follow up</h3>
              </div>
            </div>
            </div>
            {/* 3rd row */}
            <div class="flex flex-row-reverse md:contents">
              <div
                class=" col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto"
              >
                <h3 class="font-normal text-gray-700 text-base mb-1">28-03-2021</h3>
              </div>
              <div class="flex md:contents">
              <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                {/* stick */}
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-purple-800 pointer-events-none"></div>
                </div>
                {/* stick circle */}
                <div
                  class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-purple-500 shadow"
                ></div>
              </div>
              {/* col-end-13 */}
              <div
                class="col-start-6 col-span-full p-4 rounded-xl my-4 mr-auto" 
              >
                <h3 class="font-normal text-base text-gray-700 mb-1">Status changed to follow up</h3>
              </div>
            </div>
            </div>
            {/* 4th row */}
            <div class="flex flex-row-reverse md:contents">
              <div
                class=" col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto"
              >
                <h3 class="font-normal text-gray-700 text-base mb-1">28-03-2021</h3>
              </div>
              <div class="flex md:contents">
              <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                {/* stick */}
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-purple-800 pointer-events-none"></div>
                </div>
                {/* stick circle */}
                <div
                  class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-purple-500 shadow"
                ></div>
              </div>
              {/* col-end-13 */}
              <div
                class="col-start-6 col-span-full p-4 rounded-xl my-4 mr-auto" 
              >
                <h3 class="font-normal text-base text-gray-700 mb-1">Status changed to follow up</h3>
              </div>
            </div>
            </div>
            {/* 4th row end */}
            {/* <div class="flex md:contents">
              <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                <div class="h-full w-6 flex items-center justify-center">
                  <div class="h-full w-1 bg-purple-800 pointer-events-none"></div>
                </div>
                <div
                  class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-purple-500 shadow"
                ></div>
              </div>

            </div> */}
            
          </div>
        </div>


                      </div>
                  
                  </div>

                 
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


