/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'


const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PublicViewPage() {
  return (
    <Disclosure as="nav" className="sticky top-0 bg-white border-b border-gray-200">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
              </div>
                    <div className="flex-1 flex ">
                    <div className="font-medium px-8 py-2 hover:bg-gray-600 hover:text-white rounded-md cursor-pointer  items-center justify-center sm:items-stretch sm:justify-start">Home</div>
                    <div className="font-medium px-8 py-2 hover:bg-gray-600 hover:text-white rounded-md cursor-pointer  items-center justify-center sm:items-stretch sm:justify-start">Map</div>
                    <div className="font-medium px-8 py-2 hover:bg-gray-600 hover:text-white rounded-md cursor-pointer  items-center justify-center sm:items-stretch sm:justify-start">Gallery</div>
                    <div className="font-medium px-8 py-2 hover:bg-gray-600 hover:text-white rounded-md cursor-pointer  items-center justify-center sm:items-stretch sm:justify-start">Specifications</div>
                    <div className="font-medium px-8 py-2 hover:bg-gray-600 hover:text-white rounded-md cursor-pointer  items-center justify-center sm:items-stretch sm:justify-start">Contact us</div>
               
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                
                {/* Profile dropdown */}
                
              </div>
            </div>
          </div>

         
        </>
      )}
    </Disclosure>
  )
}
