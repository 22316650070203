
const ADMIN_ROLE = "admin";
const ACCOUNTANT_ROLE = "accountant";

//request activity types
const APPROVED = "APPROVED";
const APPROVED_BY = "APPROVED";
const REJECTED = "REJECTED";

export const APPROVALS_REQUIRED_IN_ORDER = [ADMIN_ROLE, ACCOUNTANT_ROLE];

export const getRequestActivityTypeStr = (type) => {
  switch (type) {
    case APPROVED:
      return 'Approved';
    case APPROVED_BY:
      return 'Approved By';
    case REJECTED:
      return 'Rejected';
    default:
      return '';
  }
};

export const isRequestApprovedByEveryone = (approvedByList) => {
  if (!approvedByList) {
    approvedByList = [];
  }
  let approvalCount = 0;

  for (let index = 0; index < approvedByList.length; index++) {
    if (approvedByList[index] === APPROVALS_REQUIRED_IN_ORDER[index]) {
      approvalCount++;
    } else {
      return false;
    }
  }

  if (approvalCount === APPROVALS_REQUIRED_IN_ORDER.length) {
    return true;
  }
  return false;
}

function ucfirst(str) {
  var firstLetter = str.substr(0, 1);
  return firstLetter.toUpperCase() + str.substr(1);
}

export const getPendingApproversNames = (approvedByList) => {
  if (!approvedByList) {
    approvedByList = [];
  }
  let pendingApprovalName = "";
  for (const approvalRole of APPROVALS_REQUIRED_IN_ORDER) {
    if (!approvedByList.includes(approvalRole)) {
      if (pendingApprovalName.length === 0) {
        pendingApprovalName = ucfirst(approvalRole);
      } else {
        pendingApprovalName = pendingApprovalName + ", " + ucfirst(approvalRole);
      }
      
    }
  }
  
  return pendingApprovalName;
}

export const isRequestApprovedByUserRole = (approvedByList = [], role) => {
  if (!approvedByList) {
    approvedByList = [];
  }
  
  return approvedByList.includes(role);
}

export const showThisRequestToUser = (approvedByList = [], role) => {
  if (!approvedByList) {
    approvedByList = [];
  }
  if (approvedByList.includes(role)) {
    //already approved show
    return true;
  }

  if (!APPROVALS_REQUIRED_IN_ORDER.includes(role)) {
    //he is not approver don't show
    return false;
  }

  //is this guy the next approver
  for (let index = 0; index < APPROVALS_REQUIRED_IN_ORDER.length; index++) {
    if (approvedByList.includes(APPROVALS_REQUIRED_IN_ORDER[index])) {
      //already approved .. go to next
      continue;
    }
    if (role === APPROVALS_REQUIRED_IN_ORDER[index]) {
      //he is the next approver
      return true;
    } else {
      return false;
    }
  }

  return false;
}