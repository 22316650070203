import React from "react";
import moment from 'moment';
import {
  CircularProgress
} from "@material-ui/core";

const TimelineView = (props) => {

    
    return(                
    
      <div class="container">
        
        <div className="font-bold py-4">
          Request Timeline
        </div>

        {props.inProgress && <CircularProgress style={{marginTop:"20px", marginLeft :"40%"}}/>}

         { !props.inProgress && props.data.map((timeLineItem, index) => {
                return(
          <div className="flex flex-col md:grid grid-cols-12  text-blue-50">
            {/* first row */}
            <div className="flex flex-row-reverse md:contents">
              <div className=" col-start-1 col-end-5 rounded-xl mb-5 -mt-1 ml-auto">
                <h3 className="text-gray-700 font-normal text-base mb-1">{timeLineItem.timestamp ? moment(timeLineItem.timestamp).format("DD MMM HH:mm a") : ""}</h3>
              </div>
              <div className="flex md:contents">
                  
              <div className="col-start-5 col-end-6 mr-10 md:mx-auto">
                 {/* circle */}
                <div className="w-4 h-4 rounded-full bg-purple-800 shadow"
                ></div>
                          {/* stick  */}
                    
              {index !== props.data.length - 1 && <div className="h-full w-4 flex items-center justify-center">
                <div className="h-full w-0.5 bg-gray-700 pointer-events-none"></div>
              </div>}
                
                
              </div>
              {/* col-end-13 */}
              <div className="col-start-6 col-span-full rounded-xl mb-5 -mt-1 mr-auto">
                <h3 className="font-normal text-base text-gray-700 mb-1">{timeLineItem.title}</h3>
                <h4 className="font-normal text-base text-gray-700 mb-1">{timeLineItem.description}</h4>
              </div>
            </div>
            </div>
          </div>
         );
         
         })} 

    </div>
    )}
export default TimelineView;