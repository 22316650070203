import React, { useState, useEffect } from "react";
import { Button, TableBody } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  makeStyles,
  InputLabel,
  TextField,
  Paper
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { connect } from "react-redux";
import { API } from 'aws-amplify';

let months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let years = ["2019", "2020", "2021"];

function getCount(stateName, reportData) {
  let count = 0;

  for (const sourceKey of Object.keys(reportData)) {
    let sourceItem = reportData[sourceKey];
    if (sourceItem.statusReports[stateName]) {
      count = count + sourceItem.statusReports[stateName].count;
    }
  }
  return count;
}

function getTotalCount(reportData) {
  let count = 0;

  for (const sourceKey of Object.keys(reportData)) {
    let sourceItem = reportData[sourceKey];
    count = count + sourceItem.count;
  }
  return count;
}

function GetReportsTable(projectName, sourceReport) {
  return (
    <div className="col-span-full xl:col-span-8 mt-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">{projectName}</h2>
      </header>
      <div className="p-3">
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Source</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Total</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">New</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">FollowUp</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Dead</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Site Visit</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">SVDone</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-gray-100">
            {Object.values(sourceReport).map((report) => {
              // let source = reportData.sourceReports[sourceName];
              return (
                <tr>
                  <td className="p-2">
                    <div className="text-left">
                      {report.name}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">
                      {report.count}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">
                      {report.statusReports.NEW
                        ? report.statusReports.NEW.count
                        : 0}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">
                      {report.statusReports.FOLLOW_UP
                        ? report.statusReports.FOLLOW_UP.count
                        : 0}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">
                      {report.statusReports.DEAD
                        ? report.statusReports.DEAD.count
                        : 0}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">
                      {report.statusReports.SITE_VISIT
                        ? report.statusReports.SITE_VISIT.count
                        : 0}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">
                    {report.statusReports.SITE_VISIT_DONE
                      ? report.statusReports.SITE_VISIT_DONE.count
                      : 0}
                    </div>
                  </td>
                </tr>
              );
            })}
              <tr>
              <td className="p-2">
                <div className="text-left">
                    Total
                </div>
              </td>
              <td className="p-2">
                <div className="text-center">
                {getTotalCount(sourceReport)}
                </div>
              </td>
              <td className="p-2">
                <div className="text-center">
                  {getCount("NEW", sourceReport)}
                </div>
              </td>
              <td className="p-2">
                <div className="text-center">
                  {getCount("FOLLOW_UP", sourceReport)}
                </div>
              </td>
              <td className="p-2">
                 <div className="text-center">    
                    {getCount("DEAD", sourceReport)}
                </div>
              </td>
              <td className="p-2">
                <div className="text-center"> 
                  {getCount("SITE_VISIT", sourceReport)}
                </div>
              </td>
              <td className="p-2">
                <div className="text-center">
                  {getCount("SITE_VISIT_DONE", sourceReport)}
                </div>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

const ReportsPage = (props) => {
  const [sourceReport, setsourceReports] = useState([]);
  const [isClickable, setOnclickable] = useState(false);
  const [selectDateRange, setDateRange] = useState(false);
  const [selectMonthRange, setMonthRange] = useState(false);
  const [projectId, setProjectId] = useState([]);
  const [clientId, setClientID] = useState([]);
  const [reportType, setReportType] = useState([]);
  const [selectedMonth, setselectedMonth] = useState([]);
  const [selectedYear, setselectedYear] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setloading] = useState(false);
  const startTimestamp = Math.floor(startDate.getTime() / 1000);
  const endTimestamp = Math.floor(endDate.getTime() / 1000);

  const getData = () => {
    setTimeout(() => {
      setloading(false);

      axios
        .get(
          `https://biztracker.in/report?clientId=${clientId}&projectId=${projectId}&month=${selectedMonth}&year=${selectedYear}&type=${reportType}&secretKey=paIORvFvhHcOZdVGrhmtdeBxhQcduA2a&startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`
        )
        .then((response) => {
          const data = response.data;
          setsourceReports(data.sourceReports);
          setOnclickable(true);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }, 2000);
  };

  const handleButton = () => {
    setOnclickable(false);
    setloading(true);
    getData();
  };

  const handleDateRange = () => {
    setDateRange(true);
    setMonthRange(false);
  };

  const handleMonthRange = () => {
    setMonthRange(true);
    setDateRange(false);
  };

  const handleOverallReport = () => {
    setMonthRange(false);
    setDateRange(false);
  };



  const useStyles = makeStyles({
    table: {
    },
    formControl: {
      minWidth: 120,
    },
  });

  const classes = useStyles();
  return (
    <div>
      <div>
        <Paper style={{width: 350, padding: 20, marginLeft: "auto", marginRight:"auto"}}>
          <FormControl className={classes.formControl}>
            <InputLabel
              id="project-label"
              className="font-weight-bold"
            >
              Project
            </InputLabel>
            <Select labelId="project-label">
              {props.projects.data.map((data) => {
                return (
                  <MenuItem
                    key={data.projectID}
                    onClick={() => {
                      setProjectId(data.projectId);
                      setClientID(data.clientId);
                    }}
                    value={data.name}
                  >
                    {data.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{marginLeft: 30}} className={classes.formControl}>
            <InputLabel
              id="report-label"
              className="font-weight-bold"
            >
              Report Type
            </InputLabel>
            <Select labelId="report-label">
              <MenuItem
                value={10}
                default
                onClick={() => {
                  handleOverallReport();
                  setReportType("OVERALL_REPORT");
                }}
              >
                Overall Report
              </MenuItem>
              <MenuItem
                value={20}
                onClick={() => {
                  handleMonthRange();
                  setReportType("MONTHLY_REPORT");
                }}
              >
                Monthly Report
              </MenuItem>
              <MenuItem
                value={30}
                onClick={() => {
                  handleDateRange();
                  setReportType("DATE_RANGE_REPORT");
                }}
              >
                Date Range
              </MenuItem>
            </Select>
          </FormControl>

        {selectMonthRange && (
          <div style={{marginTop: 20}}>
            <FormControl className={classes.formControl}>
              <InputLabel
                id="month-label"
                className="font-weight-bold"
              >
                Month
              </InputLabel>

              <Select
                labelId="month-label"
              >
                {months.map((item, index) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setselectedMonth(index + 1);
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl style={{marginLeft: 20}} className={classes.formControl}>
              <InputLabel
                id="year-label"
                className="font-weight-bold"
              >
                Year
              </InputLabel>
              <Select
              labelId="year-label"
              >
                
                {years.map((item) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setselectedYear(item);
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}
        {selectDateRange && (
          <div className="d-flex justify-content-center m-3">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
            />

            <TextField
              id="start-date"
              label="Start Date"
              type="date"
              defaultValue={startDate}
              onChange={(date) => setStartDate(date)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />

<TextField
              id="end-date"
              label="end Date"
              type="date"
              defaultValue={endDate}
              onChange={(date) => setEndDate(date)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <h5 className="ml-5 mr-5 p-2">to</h5>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              className="p-2"
            />
          </div>
        )}
        <div className="text-center mt-4">
            
          <button onClick={() => handleButton()} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none">
              Get Report
            </button>
        </div>
        </Paper>

        {loading && (
          <div style={{ fontWeight: "bold", marginTop: "2%", textAlign: "center" }}>
            {" "}
            Loading ...{" "}
          </div>
        )}
      </div>

      {isClickable ? GetReportsTable("Project Name",sourceReport) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    projects: state.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsPage);
