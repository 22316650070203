import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  makeStyles,
  InputLabel,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";

const UserReports = (props) => {
  console.log("is USerReports");
  const [sourceReport, setsourceReports] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [isClickable, setOnclickable] = useState(false);
  const [selectDateRange, setDateRange] = useState(false);
  const [selectMonthRange, setMonthRange] = useState(false);
  const [projectId, setProjectId] = useState([]);
  const [clientId, setClientID] = useState([]);
  const [reportType, setReportType] = useState([]);
  const [selectedMonth, setselectedMonth] = useState([]);
  const [selectedYear, setselectedYear] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setloading] = useState(false);
  const startTimestamp = Math.floor(startDate.getTime() / 1000);
  const endTimestamp = Math.floor(endDate.getTime() / 1000);

  useEffect(() => {
    //data will be loaded everytime page renders
    getProjectData();
  }, []);

  const getData = () => {
    setTimeout(() => {
      setloading(false);
      axios
        .get(
          `https://biztracker.in/report?clientId=${clientId}&projectId=${projectId}&month=${selectedMonth}&year=${selectedYear}&type=${reportType}&secretKey=paIORvFvhHcOZdVGrhmtdeBxhQcduA2a&startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`
        )
        .then((response) => {
          const data = response.data;
          setsourceReports(data.sourceReports);
          setOnclickable(true);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }, 2000);
  };

  const getProjectData = async () => {
    // const response = await fetch("https://biztracker.projectsin/mock/");

    const response = await fetch("https://biztracker.in/mock/projects");
    const data = await response.json();

    setProjectData(data);
  };

  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let years = ["2019", "2020", "2021"];
  const handleButton = () => {
    setOnclickable(false);
    setloading(true);
    getData();
  };

  const handleDateRange = () => {
    setDateRange(true);
    setMonthRange(false);
  };

  const handleMonthRange = () => {
    setMonthRange(true);
    setDateRange(false);
  };

  const handleOverallReport = () => {
    setMonthRange(false);
    setDateRange(false);
  };

  function getCount(stateName, reportData) {
    let count = 0;

    for (const sourceKey of Object.keys(reportData)) {
      let sourceItem = reportData[sourceKey];
      if (sourceItem.statusReports[stateName]) {
        count = count + sourceItem.statusReports[stateName].count;
      }
    }
    return count;
  }

  function getTotalCount(reportData) {
    let count = 0;

    for (const sourceKey of Object.keys(reportData)) {
      let sourceItem = reportData[sourceKey];
      count = count + sourceItem.count;
    }
    return count;
  }

  const useStyles = makeStyles({
    table: {
      width: 100,
      borderColor: "lightgrey",
      borderStyle: "solid",
    },
    formControl: {
      minWidth: 120,
    },
  });

  const classes = useStyles();
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div className="d-flex justify-content-center m-3">
          <h5 className="p-3">Select Report :</h5>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-filled-label"
              className="font-weight-bold"
            >
              Project
            </InputLabel>

            <Select>
              {projectData.map((data) => {
                return (
                  <MenuItem
                    key={data.projectID}
                    onClick={() => {
                      setProjectId(data.projectId);
                      setClientID(data.clientId);
                    }}
                    value={data.name}
                  >
                    {data.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div className="d-flex justify-content-center m-3">
          <h5 className="p-3">Select Month :</h5>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-filled-label"
              className="font-weight-bold"
            >
              Report
            </InputLabel>
            <Select>
              <MenuItem
                value={10}
                onClick={() => {
                  handleOverallReport();
                  setReportType("OVERALL_REPORT");
                }}
              >
                Overall Report
              </MenuItem>
              <MenuItem
                value={20}
                onClick={() => {
                  handleMonthRange();
                  setReportType("MONTHLY_REPORT");
                }}
              >
                Monthly Report
              </MenuItem>
              <MenuItem
                value={30}
                onClick={() => {
                  handleDateRange();
                  setReportType("DATE_RANGE_REPORT");
                }}
              >
                Select Report by Date Range
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        {selectMonthRange && (
          <div className="d-flex justify-content-center m-3">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-filled-label"
                className="font-weight-bold"
              >
                Month
              </InputLabel>

              <Select>
                {months.map((item, index) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setselectedMonth(index + 1);
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-filled-label"
                className="font-weight-bold"
              >
                Year
              </InputLabel>
              <Select>
                {years.map((item) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setselectedYear(item);
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}
        {selectDateRange && (
          <div className="d-flex justify-content-center m-3">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              className="p-2"
            />
            <h5 className="ml-5 mr-5 p-2">to</h5>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              className="p-2"
            />
          </div>
        )}
        <div className="d-flex justify-content-center m-4">
          <Button
            style={{ width: "20%", marginTop: "2%" }}
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            onClick={() => handleButton()}
          >
            GET REPORT
          </Button>
        </div>

        {loading && (
          <div style={{ fontWeight: "bold", marginTop: "2%" }}>
            {" "}
            Loading ...{" "}
          </div>
        )}
      </div>
      {isClickable && (
        <TableContainer>
          <Table
            aria-label="simple table"
            className={classes.table}
            // style={{ marginLeft: "33%" }}
            style={{ marginLeft: "auto", marginRight: "auto", marginTop: "2%" }}
          >
            <TableHead>
              <TableCell>Source </TableCell>
              <TableCell>Total</TableCell>
              <TableCell>New</TableCell>
              <TableCell>FollowUp</TableCell>
              <TableCell>DEAD</TableCell>
              <TableCell>SiteVisit</TableCell>
              <TableCell>SVDone</TableCell>
            </TableHead>

            {Object.values(sourceReport).map((report) => {
              return (
                <TableRow>
                  <TableCell>{report.name} </TableCell>
                  <TableCell className="text-center">{report.count}</TableCell>
                  <TableCell className="text-center">
                    {report.statusReports.NEW
                      ? report.statusReports.NEW.count
                      : 0}
                  </TableCell>
                  <TableCell className="text-center">
                    {report.statusReports.FOLLOW_UP
                      ? report.statusReports.FOLLOW_UP.count
                      : 0}
                  </TableCell>
                  <TableCell className="text-center">
                    {report.statusReports.DEAD
                      ? report.statusReports.DEAD.count
                      : 0}
                  </TableCell>

                  <TableCell className="text-center">
                    {report.statusReports.SITE_VISIT
                      ? report.statusReports.SITE_VISIT.count
                      : 0}
                  </TableCell>
                  <TableCell className="text-center">
                    {report.statusReports.SITE_VISIT_DONE
                      ? report.statusReports.SITE_VISIT_DONE.count
                      : 0}
                  </TableCell>
                </TableRow>
              );
            })}

            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell className="text-center">
                {getTotalCount(sourceReport)}
              </TableCell>
              <TableCell className="text-center">
                {getCount("NEW", sourceReport)}
              </TableCell>
              <TableCell className="text-center">
                {getCount("FOLLOW_UP", sourceReport)}
              </TableCell>
              <TableCell className="text-center">
                {getCount("DEAD", sourceReport)}
              </TableCell>
              <TableCell className="text-center">
                {getCount("SITE_VISIT", sourceReport)}
              </TableCell>
              <TableCell className="text-center">
                {getCount("SITE_VISIT_DONE", sourceReport)}
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
export default UserReports;
