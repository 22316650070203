import React, { useState } from "react";


const RequestView = (props) => {
  let userInfo = props.userInfo;
  let property = props.property;
  let requestToHoldSubmitted = false;
  let requestToBookSubmitted = false;
  let isPreviousRequestCancelled = false;

  if (props.isPublic && (property.status === "AVAILABLE" || property.status === "ON_HOLD")) {
    return (
      <div className="p-4">
        <button type="button" onClick={props.onLeadSubmitClick} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700">
          I am Intrested
        </button>
      </div>)
  }

  if (userInfo.role !== "agent") {
    return null;
  }
  let requestId = "";
  for (const item of props.requests) {
    if (item.type === "REQUEST_TO_HOLD" && property.propertyId === item.propertyId && property.status === "AVAILABLE") {
      if (item.status === "PENDING") {
        requestToHoldSubmitted = true;
        requestId = item.requestId;
      }
    }

    if (item.type === "REQUEST_TO_BOOK" && property.propertyId === item.propertyId && property.status === "ON_HOLD") {
      if (item.status === "PENDING") {
        requestToBookSubmitted = true;
        requestId = item.requestId;
      }
    }
  }

  
  if (props.property.status === "AVAILABLE") {
    if (requestToHoldSubmitted) {
      return (
        <div className="p-4">
          <button type="button" disabled={true} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white cursor-not-allowed">
            Request to Hold has been Submitted
          </button>
          <button type="button" onClick={()=>props.onCancelHoldRequestClick(requestId)} className="w-full inline-flex justify-center mt-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white">
            Cancel hold request
          </button>
        </div>)
    } else {
      return (
        <div className="p-4">
          <button type="button" onClick={props.onRequestToHoldClick} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700">
            Request to Hold
          </button>
        </div>
      )
    }
  } else if (props.property.status === "ON_HOLD") {
    if (requestToBookSubmitted) {
      return (
        <div className="p-4">
          <button type="button" disabled={true} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white cursor-not-allowed">
            Request to Book has been Submitted
          </button>
          <button type="button" onClick={()=>props.onCancelBookRequestClick(requestId)} className="w-full inline-flex justify-center mt-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white">
            Cancel Book request
          </button>
        </div>)
    } else if (props.property.assignedTo === userInfo.userId) {
      //request to book should be displayed only to the user who has on hold request
      return (
        <div className="p-4">
          <button type="button" onClick={props.onRequestToBookClick} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700">
            Request to Book
        </button>
        </div>
      )
    } else {
      //TODO: decide what to show for users if its onHold by some other user 
    }
  }
    
  return null;
}

export default RequestView;