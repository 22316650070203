import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Snackbar
} from '@material-ui/core';

const SimpleSnackbar = (props) => {
  let actions = [];
  if (props.onClick) {
    actions = [
      <Button key="undo" color="secondary" size="small" onClick={props.onClick}>
        {props.buttonText}
      </Button>
    ];
  }

  return (
    <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={props.show}
          autoHideDuration={props.autoHideDuration}
          onClose={props.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{props.message}</span>}
          action={actions}
        />
  );
};

SimpleSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
  show: PropTypes.bool,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

SimpleSnackbar.defaultProps = {
  autoHideDuration: 60000,
  buttonText: 'RETRY'
};

export default SimpleSnackbar;
