import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { connect } from "react-redux";
import LeadsDetailsModal from './LeadsDetailsModal';
import AuthContext from '../shared/utils/authContext';
import {
  listStatusChangeRequests , leadsByAssignedToFollowUpAt
} from '../shared/graphql/queries';
import {
  createStatusChangeRequest
} from '../shared/graphql/mutations';
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  TextField
} from "@material-ui/core";
import SimpleSnackbar from "../components/shared/SimpleSnackbar";
import {
  getUserNameFromUserList, getRequestTypeText, getStatusText, getLeadSourceText, getCaption
} from '../shared/utils'

const LeadsPage = (props) => {
  useEffect(() => {
    getData();
  }, []);


const [isLoading, setIsLoading] = useState(true);
const [isError, setIsError] = useState(false);
const [requests, setRequests] = useState([]);
const [leadsOpen, setLeadsOpen] = useState(false);
const [request, setRequest] = useState({});
const userInfo = useContext(AuthContext);
const [leadsData, setLeadsData] = useState([]);
const [leadData, setLeadData] = useState({});

  const getData = () => {
    setIsLoading(true);
    API.graphql(graphqlOperation(leadsByAssignedToFollowUpAt, {
      assignedTo: userInfo.userId,
      followUpAt: {
        between: [0, timestamp]
      },
      limit: 75
    })).then((response) => {
      console.log("leadsByAssignedToFollowUpAt", response);
      let data = response.data.LeadsByAssignedToFollowUpAt.items;
      setLeadsData(data);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      setIsError(true);
    
      console.warn(err);
    });
  }

  console.log("Users list", props.users.data);
  console.log("requests", requests);



  const getLeadStatustype = (status) => {
    switch (status) {
      case 'NEW' :
          return <span className="text-xs font-semibold inline-block py-2 px-2 uppercase rounded text-yellow-600 bg-yellow-200 last:mr-0 mr-1">
                 New</span>
      case 'FOLLOW_UP' :
           return <span className="text-xs font-semibold inline-block py-2 px-2 uppercase rounded text-yellow-600 bg-yellow-200 last:mr-0 mr-1">
       Follow up
       </span>
      case 'SITE_VISIT' :
         return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-green-600 bg-green-100 last:mr-0 mr-1">
       Site Visit
       </span>
      case 'COMPLETE' :
          return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-gray-600 bg-gray-200 last:mr-0 mr-1">
       Complete
       </span>
      case 'CLOSED' :
          return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-pink-600 bg-pink-200 last:mr-0 mr-1">
           Closed
           </span>
      case 'DEAD' :
        return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-pink-600 bg-pink-200 last:mr-0 mr-1">
           Dead
           </span>
      case 'SITE_VISIT_DONE' :
        return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-pink-600 bg-pink-200 last:mr-0 mr-1">
           Site visit done
           </span>
      case 'DEAD_AFTER_SITE_VISIT':
        return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-pink-600 bg-pink-200 last:mr-0 mr-1">
           Dead after site visit
           </span>
      case 'ALL':
        return 'All';
      default:
        //TODO: change it to null and test
          return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-pink-600 bg-pink-200 last:mr-0 mr-1">
           Unknown
           </span>
    }
  };

  // const getLeadStatustype = (status) => {
  //   if (status === "FOLLOW_UP") {
  //     return <span className="text-xs font-semibold inline-block py-2 px-2 uppercase rounded text-yellow-600 bg-yellow-200 last:mr-0 mr-1">
  //       Follow up
  //     </span>
  //   } else if (status === "SITE_VISIT") {
  //     return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-green-600 bg-green-100 last:mr-0 mr-1">
  //       Site Visit
  //      </span>
  //   } else if (status === "COMPLETE") {
  //     return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-gray-600 bg-gray-200 last:mr-0 mr-1">
  //       Complete
  //      </span>
  //   }
  //   else { <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-pink-600 bg-pink-200 last:mr-0 mr-1">
  //           Unknown
  //          </span>}
    
  // }

  const onLeadsClick = (leadData) =>{

    setLeadData(leadData);
    setLeadsOpen(true);
  }

  let timestamp = Math.floor(Date.now()/ 1000);

  if (isLoading) {
    return <CircularProgress style={{marginTop:"20%", marginLeft :"50%"}}/>;
  } else if (isError) {
    return <div> <SimpleSnackbar show={true} message="Failed to fetch data" buttonText="Retry" onClick={getData}/></div>;
  }

  return (
   <div> 
       
       { leadsData.map((leadData)=> {
      return(  
        <div onClick={()=> onLeadsClick(leadData) } className="rounded bg-white shadow p-5 my-2 cursor-pointer w-full md:w-3/6 mx-auto">
              <div>
                  <div className="flex flex-row justify-between">
                  <div className="font-semibold text-gray-600 mt-1">{leadData.name}</div>
                  <div className="flex-end mt-1 hover:text-purple-700">{getLeadStatustype(leadData.status)}</div>
                  </div>
                  <div>
                  <div className="text-gray-600 mt-1 flex-center">{getCaption(leadData)}</div>
                  <div className="text-gray-600 mt-1 flex-center">{getLeadSourceText(leadData.source)}</div>
                  <div className="text-gray-600 mt-1 flex-center">{leadData.message}</div>
                  </div>
                  <div className="flex pt-2 justify-between space-x-2">
       
                  <button class="bg-transparent md:w-3/6 text-sm hover:bg-gray-500 text-black-700 font-semibold hover:text-white py-2 px-4 border border-gary-500 hover:border-transparent rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 8l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z" />
                  </svg>CALL
                </button>
                <button class="bg-transparent md:w-3/6 text-sm hover:bg-gray-500 text-black-700 font-semibold hover:text-white py-2 px-4 border border-gary-500 hover:border-transparent rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg> MAIL
                </button>
                  </div>
              </div>
            </div>
     );
   
    })}

<LeadsDetailsModal 
              setLeadsOpen={setLeadsOpen}
              leadsOpen={leadsOpen}
              leadData={leadData}
              setLeadData={setLeadData}
              leadsData={leadsData}
              setLeadsData={setLeadsData}
              getLeadStatustype={getLeadStatustype}/> 

   </div>
  );
};

function mapStateToProps(state) {
  return {
    users: state.users,
    projects: state.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadsPage);


// const addRequest = (propertyId) => {
//   API.graphql(graphqlOperation(
//     createStatusChangeRequest, {
//       input: {
//         clientId: "d8afa3df-49bf-48ce-a04e-d3d03ac540ea",
//         projectId: "705a8b9e-dad3-4782-807b-1d99a430378e",
//         requestedBy: "f7bca46c-4a0d-469c-bc5c-f8e609f38480",
//         requestId: Math.floor(Date.now() / 1000),
//         propertyItemId: propertyId,
//         type: "REQUEST_TO_HOLD",
//       }
//     })).then((response) => {
//     console.log("createStatusChangeRequest", response);
//   }).catch((err) => {
//     console.warn(err);
//   });
// };