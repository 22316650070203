import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { connect } from "react-redux";
import SalesModal from './SalesModal';
import {
  listStatusChangeRequests
} from '../shared/graphql/queries';
import {
  createStatusChangeRequest
} from '../shared/graphql/mutations';
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  TextField
} from "@material-ui/core";
import SimpleSnackbar from "../components/shared/SimpleSnackbar";
import {
  getUserNameFromUserList, getRequestTypeText, getStatusText, getLeadSourceText, getCaption
} from '../shared/utils'

var SalesDataView = [{

  "saleId" : "123",
  "propertyId": "101",
  "status":"PENDING_PAYMENT",
  "customer": {
      "name" : "Sanath",
      "email": "123@gmail.com",
      "phoneNumber" :" 2345234570"
  }
},
{
  "saleId" : "123",
  "propertyId": "102",
  "status":"PAYMENT_DONE",
  "customer": {
      "name" : "Vishita",
      "email": "123@gmail.com",
      "phoneNumber" :" 2345234570"
  }
},
{
  "saleId" : "123",
  "propertyId": "103",
  "status":"PAYMENT_DONE",
  "customer": {
      "name" : "Sanath",
      "email": "123@gmail.com",
      "phoneNumber" :" 2345234570"
  }
}]

const SalesPage = (props) => {
  useEffect(() => {
    getData();
  }, []);

  const getSalesStatusView = (type) => {
    if (type === "PENDING_PAYMENT") {
      return <span className="text-xs font-semibold inline-block py-2 px-2 uppercase rounded text-yellow-600 bg-yellow-100 last:mr-0 mr-1">
        Pending Payment
      </span>
    } else if (type === "REQUEST_TO_HOLD") {
      return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-600 bg-yellow-200 last:mr-0 mr-1">
        Request to Hold
       </span>
    } else if (type === "PAYMENT_DONE") {
      return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-green-600 bg-green-200 last:mr-0 mr-1">
        Payment Done
       </span>
    }
    else { <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-pink-600 bg-pink-200 last:mr-0 mr-1">
            Unknown
           </span>}
    
  }
const [isLoading, setIsLoading] = useState(true);
const [isError, setIsError] = useState(false);
const [requests, setRequests] = useState([]);
const [salesOpen, setSalesOpen] = useState(false);
const [request, setRequest] = useState({});
const [saleItem,setSaleItem] = useState({});

  const getData = () => {
    setIsLoading(true);
    API.graphql(graphqlOperation(
      listStatusChangeRequests, {
      projectId: "705a8b9e-dad3-4782-807b-1d99a430378e"
    })).then((response) => {
      console.log("listStatusChangeRequests", response.data.listStatusChangeRequests.items);
      // setProjects = response.data.listStatusChangeRequests.items
      setRequests(response.data.listStatusChangeRequests.items);
      setIsLoading(false);

    }).catch((err) => {
      setIsLoading(false);
      setIsError(true);
      console.warn(err);
    });
  }

  
  console.log("Users list", props.users.data);
  console.log("requests", requests);

  if (isLoading) {
    return <CircularProgress style={{marginTop:"20%", marginLeft :"50%"}}/>;
  } else if (isError) {
    return <div> <SimpleSnackbar show={true} message="Failed to fetch data" buttonText="Retry" onClick={getData}/></div>;
  }

  const onSalesClick = (saleItem) =>{

    setSaleItem(saleItem);
    setSalesOpen(true);
  }

  return (
    <div> 
          
        { SalesDataView.map((saleItem)=> {
       return(  
         <div onClick={()=> onSalesClick(saleItem) } className="rounded bg-white shadow p-5 my-2 cursor-pointer w-full md:w-3/6 mx-auto">
               <div>
                   <div className="flex flex-row justify-between">
                   <div className="font-semibold text-gray-600 mt-1">{saleItem.propertyId}</div>
                    <div className="flex-end mt-1 hover:text-purple-700">{getSalesStatusView(saleItem.status)}</div>
                   {/* <div className="flex-end mt-1 hover:text-purple-700">{getLeadStatustype(request.status)}</div> */}
                   </div>
                   <div>
                   <div className="text-gray-600 mt-1 flex-center">{saleItem.customer.name}</div>
                   {/* <div className="text-gray-600 mt-1 flex-center">{getLeadSourceText(request.source)}</div> */}
                   <div className="text-gray-600 mt-1 flex-center">{saleItem.customer.email}</div>
                   </div>
                   
               </div>
             </div>
      );
    
     })}
 
 <SalesModal 
              setSalesOpen={setSalesOpen}
              salesOpen={salesOpen}
              request={request}
              usersList={props.users.data}
              SalesDataView={SalesDataView}
              getSalesStatusView={getSalesStatusView}
              saleItem={saleItem}
               /> 
 
    </div>
   );

  // return (
  //  <div> { requests.map((request)=> {
  //  return(  
  //    <div key={request.requestId} onClick={()=> {
  //       setSalesOpen(true) 
  //         setRequest(request)}} 
  
  //className="rounded bg-white shadow p-5 my-2 cursor-pointer w-full md:w-3/6 mx-auto">
  //           <div className="flex flex-row justify-between">
  //             <div className="font-semibold text-gray-600 mt-2">{request.propertyId}</div>
  //             <div className="text-gray-600 mt-2 flex-center">{getUserNameFromUserList(props.users.data, request.requestedBy)}</div>
  //             <div className="flex-end mt-2 hover:text-purple-700">
  //             {getRequestTypeText(request.type)}
  //             </div>
  //           </div>
  //          <SalesModal 
  //             setSalesOpen={setSalesOpen}
  //             salesOpen={salesOpen}
  //             request={request}
  //             usersList={props.users.data}/> 
  //       </div>
          

  //  );
   
  //   })}


  //  </div>
  // );
};

function mapStateToProps(state) {
  return {
    users: state.users,
    projects: state.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesPage);


// const addRequest = (propertyId) => {
//   API.graphql(graphqlOperation(
//     createStatusChangeRequest, {
//       input: {
//         clientId: "d8afa3df-49bf-48ce-a04e-d3d03ac540ea",
//         projectId: "705a8b9e-dad3-4782-807b-1d99a430378e",
//         requestedBy: "f7bca46c-4a0d-469c-bc5c-f8e609f38480",
//         requestId: Math.floor(Date.now() / 1000),
//         propertyItemId: propertyId,
//         type: "REQUEST_TO_HOLD",
//       }
//     })).then((response) => {
//     console.log("createStatusChangeRequest", response);
//   }).catch((err) => {
//     console.warn(err);
//   });
// };