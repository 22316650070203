import React from "react";

const PropertyStatusView = (props) => {
  if (props.status === "AVAILABLE") {
    return <span className="text-xs font-semibold inline-block py-2 px-2 uppercase rounded text-green-600 bg-green-100 last:mr-0 mr-1">
      Available
    </span>
  } else if (props.status === "ON_HOLD") {
    return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-600 bg-yellow-200 last:mr-0 mr-1">
      On Hold
     </span>
  } else if (props.status === "SOLD") {
    return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-gray-600 bg-gray-200 last:mr-0 mr-1">
      Sold
     </span>
  }

  return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-pink-600 bg-pink-200 last:mr-0 mr-1">
          Unknown
     </span>
  
}

export default PropertyStatusView;