import React, { useState, useEffect, useContext} from "react";
import AuthContext from '../shared/utils/authContext';
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  TextField
} from "@material-ui/core";
import SimpleSnackbar from "../components/shared/SimpleSnackbar";
import { connect } from "react-redux";
import { API, graphqlOperation } from 'aws-amplify';
import PropertiesListView from '../components/PropertiesListView';
import PropertiesLayoutView from '../components/PropertiesLayoutView';
import PropertyDetailView from '../components/PropertyDetailView';
import {
  listPropertys,
  listStatusChangeRequests
} from '../shared/graphql/queries';
import {
  createProperty
} from '../shared/graphql/mutations';
import {
  setPropertiesAction,
  updatePropertyAction
} from '../shared/actions/propertiesActions';
import {
  setRequestsAction
} from '../shared/actions/requestsActions';

export const ALL = 'ALL';
export const PROPETY_AVAILABLE = 'AVAILABLE';
export const PROPETY_HOLD = 'ON_HOLD';
export const PROPETY_SOLD = 'SOLD';

const LIST_VIEW_TYPE = "LIST_VIEW_TYPE";
const MAP_VIEW_TYPE = "MAP_VIEW_TYPE";

const PropertiesPage = (props) => {
  const userInfo = useContext(AuthContext);
  let properties = props.properties.data;
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [filterBy, setFilterBy] = useState(ALL);
  const [searchText, setSearchText] = useState("");
  const [viewType, setViewType] = useState(MAP_VIEW_TYPE);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    //TODO: user api .get
    //TODO: add project dropdown ?
    API.graphql(graphqlOperation(listPropertys, {
      projectId: "705a8b9e-dad3-4782-807b-1d99a430378e",
      limit: 75
    })).then((response) => {
      console.log("listPropertys", response);
      const data = response.data;
      props.setProperties(data.listPropertys.items);
      if (props.isPublic) {
        setIsLoading(false);
      } else {
        getRequestsData();
      }
    }).catch((err) => {
      setIsLoading(false);
      setIsError(true);
      console.warn(err);
    });
  };

  const getRequestsData = () => {
    setIsLoading(true);
    API.graphql(graphqlOperation(listStatusChangeRequests, {
        projectId: "705a8b9e-dad3-4782-807b-1d99a430378e",
        filter: {
          requestedBy: {
            eq: userInfo.userId
          },
        },
    })).then((response) => {
      console.log("listStatusChangeRequests", response.data.listStatusChangeRequests.items);
      props.setRequests(response.data.listStatusChangeRequests.items);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      setIsError(true);
      console.warn(err);
    });
  }

  const addProperty = (propertyNumber) => {
    API.graphql(graphqlOperation(createProperty, {
        input: {
          clientId: "d8afa3df-49bf-48ce-a04e-d3d03ac540ea",
          projectId: "705a8b9e-dad3-4782-807b-1d99a430378e",
          groupId: "",
          propertyId: propertyNumber,
          propertyType: "PLOT",
          name: propertyNumber,
          status: "AVAILABLE",
          created: Math.floor(Date.now() / 1000),
          updated: Math.floor(Date.now() / 1000)
        }
      })).then((response) => {
      console.log("createProperty", response);
    }).catch((err) => {
      console.warn(err);
    });
  };

  if (isError) {

  }

  if (isLoading) {
    return <CircularProgress style={{marginTop:"20%", marginLeft :"50%"}}/>;
  } else if (isError) {
    return <div> <SimpleSnackbar show={true} message="Failed to fetch data" buttonText="Retry" onClick={getData}/></div>;
  }
  
  console.log("properties ", properties);
  const filterAndGetProperties = () => {
    let afterFilterBy = properties;

    if (filterBy !== ALL) {
      afterFilterBy = properties.filter(property => property.status === filterBy);
    }
    
    return afterFilterBy.filter(property => property.name.includes(searchText));
  }

  const getPropertyById = (id) => {
    let results = properties.filter(property => property.propertyId === id);
    if (results && results.length === 1) {
      return results[0];
    }
  }

  const activeViewTypeClassName = "py-2 px-6 shadow bg-white font-medium m-0.5 rounded-lg text-md";
  const inActiveViewTypeClassName = "py-2 px-6 text-md font-medium m-0.5 text-gray-500 bg-gray-200";
  
  console.log("properties page state ", properties);
  return (
    <div>
      <div className={"flex mx-auto pb-4" + (props.isPublic ? " hidden" :"") }>
        <div className="flex mx-auto cursor-pointer shadow rounded-l-lg bg-gray-200 rounded-r-lg">
          <div
            onClick={() => setViewType(LIST_VIEW_TYPE)}
            className={" " + (viewType === LIST_VIEW_TYPE ? activeViewTypeClassName : inActiveViewTypeClassName)}>  
            <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
            </svg>
              List View
          </div>
          <div
            onClick={() => setViewType(MAP_VIEW_TYPE)}
            className={"" + (viewType === MAP_VIEW_TYPE ? activeViewTypeClassName : inActiveViewTypeClassName)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
            </svg>
            Layout View
          </div>
        </div>
      </div>

      <div className="flex flex-row">
      
        {viewType === MAP_VIEW_TYPE ?
          <PropertiesLayoutView
            properties={properties}
            projectId={"705a8b9e-dad3-4782-807b-1d99a430378e"}
            setPropertyById={setSelectedPropertyId} />
        : <div className="w-full md:w-3/6 mx-auto">
          <div className="">
            <div className="flex justify-between px-5 w-full bg-white shadow rounded-lg mb-5 p-4">
              <TextField
                id="standard-basic"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                label="Search by name" />
            
              <FormControl style={{ width: 130 }}>
                <InputLabel
                  id="filter-property-label"
                  className="font-weight-bold"
                >
                  Filter By Status
              </InputLabel>
                <Select labelId="filter-property-label" defaultValue={filterBy}>
                  <MenuItem
                    value={ALL}
                    default
                    onClick={() => {
                      setFilterBy(ALL);
                    }}
                  >
                    All
                </MenuItem>
                  <MenuItem
                    value={10}
                    onClick={() => {
                      setFilterBy(PROPETY_AVAILABLE);
                    }}
                  >
                    Available
                </MenuItem>
                  <MenuItem
                    value={20}
                    onClick={() => {
                      setFilterBy(PROPETY_HOLD);
                    }}
                  >
                    Hold
                </MenuItem>
                  <MenuItem
                    value={30}
                    onClick={() => {
                      setFilterBy(PROPETY_SOLD);
                    }}
                  >
                    Sold
                </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div >
                <PropertiesListView
                  properties={filterAndGetProperties()}
                  setPropertyByItemId={setSelectedPropertyId} />
            </div>
          </div>
        </div>}

      {selectedPropertyId ?
        <div className="">
          <PropertyDetailView
            property={getPropertyById(selectedPropertyId)}
            requests={props.requests.data}
            updateProperty={props.updateProperty}
            isPublic={props.isPublic}
            closeView={()=> setSelectedPropertyId(null)}
          />
        </div> 
          : null}
        </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    properties: state.properties,
    requests: state.requests
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setProperties: function(data) {
      dispatch(setPropertiesAction(data));
    },
    setRequests: function (data) {
      dispatch(setRequestsAction(data));
    },
    updateProperty: function (itemId, data) {
      dispatch(updatePropertyAction(itemId, data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesPage);
