/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_OM4qhCkuv",
    "aws_user_pools_web_client_id": "vodhgd3pvoglut97e8gte2dcf",
    "oauth": {},
    "aws_user_files_s3_bucket": "biztracker-property-layout-data",
    "aws_user_files_s3_bucket_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://qkqmg6kqdvbuxog3p6j6ivu57y.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_cognito_identity_pool_id":  "ap-south-1:76036f74-e127-4425-8976-347f358cf010",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "reports",
            "endpoint": "https://1idc216df2.execute-api.ap-south-1.amazonaws.com/prod",
            "region": "ap-south-1"
        }
    ]
};


export default awsmobile;
