export const GET_TASKS = "GET_TASKS";
export const GET_TASKS_PENDING = "GET_TASKS_PENDING";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_ERROR = "GET_TASKS_ERROR";
export const SET_TASKS = "SET_TASKS";
export const UPDATE_TASKS = "UPDATE_TASKS";
export const IS_TASK_SELECTED = "IS_TASK_SELECTED";
export const ADD_TASK = "ADD_TASK";


export const GET_PROPERTIES = "GET_PROPERTIES";
export const GET_PROPERTIES_PENDING = "GET_PROPERTIES_PENDING";
export const GET_PROPERTIES_SUCCESS = "GET_PROPERTIES_SUCCESS";
export const GET_PROPERTIES_ERROR = "GET_PROPERTIES_ERROR";
export const SET_PROPERTIES= "SET_PROPERTIES";
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";
export const ADD_PROPERTY = "ADD_PROPERTY";

export const SEARCH_LEADS = "SEARCH_LEADS";
export const SEARCH_LEADS_BY_NUMBER = "SEARCH_LEADS_BY_NUMBER";
export const SEARCH_LEADS_BY_NAME = "SEARCH_LEADS_BY_NAME";
export const SEARCH_LEADS_PENDING = "SEARCH_LEADS_PENDING";
export const SEARCH_LEADS_SUCCESS = "SEARCH_LEADS_SUCCESS";
export const SEARCH_LEADS_ERROR = "SEARCH_LEADS_ERROR";

export const FETCH_MORE_SEARCH_LEADS_PENDING = "FETCH_MORE_SEARCH_LEADS_PENDING";
export const FETCH_MORE_SEARCH_LEADS_SUCCESS = "FETCH_MORE_SEARCH_LEADS_SUCCESS";
export const FETCH_MORE_SEARCH_LEADS_ERROR = "FETCH_MORE_SEARCH_LEADS_ERROR";

export const SET_SEARCH_LEADS = "SET_SEARCH_LEADS";
export const UPDATE_SEARCH_LEADS = "UPDATE_SEARCH_LEADS";
export const ADD_SEARCH_LEAD = "ADD_SEARCH_LEAD";

export const SET_USER_INFO = "SET_USER_INFO";

export const SET_USERS = "SET_USERS";
export const GET_USERS = "GET_USERS";
export const GET_USERS_PENDING = "GET_USERS_PENDING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const SET_PROJECTS = "SET_PROJECTS";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_PENDING = "GET_PROJECTS_PENDING";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_ERROR = "GET_PROJECTS_ERROR";

export const SET_REQUESTS = "SET_REQUESTS";
export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const ADD_REQUEST = "ADD_REQUEST";
export const DELETE_REQUEST = "DELETE_REQUEST";
