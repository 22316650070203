import React, { Fragment, useState, useRef } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import { Dialog, Transition } from '@headlessui/react';
import { useFormik } from 'formik';


const LeadSubmission = (props) => {
  const cancelButtonRef = useRef(null)
  const [leadSubmitInProgress, setLeadSubmitInProgress] = useState(false);  
  const [leadSubmitFailed, setLeadSubmitFailed] = useState(false);
  
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      message: ""
    },
    enableReinitialize: true,
    //validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      onLeadSubmit(values);
    },
  });

  const onLeadSubmit = () => {
    props.closeDialog();
  }


  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-40 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={props.isOpen}
        onClose={props.closeDialog}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900 ">
                      Submit Contact Information
                    </Dialog.Title>
                    <div className="mt-2">
                      <div class="w-full md:w-full mb-2 mt-4">
                        <div className="font-semibold "> Our sales team will reach out to you as soon as possible </div>
                        <div className="mt-4">
                          <div className="text-md font-semibold text-gray-600 mt-2">
                            Name
                          </div>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="name"
                              onChange={formik.handleChange}
                              value={formik.values.name}
                              className="rounded-md w-full shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "></input>
                          </div>
                        </div>

                        <div className="mt-4">
                          <div className="text-md font-semibold text-gray-600 mt-2">
                            Email
                          </div>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              className="rounded-md w-full shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "></input>
                          </div>
                        </div>

                        <div className="mt-4">
                          <div className="text-md font-semibold text-gray-600 mt-2">
                            Phone Number
                          </div>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="phoneNumber"
                              onChange={formik.handleChange}
                              value={formik.values.phoneNumber}
                              className="rounded-md w-full shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "></input>
                          </div>
                        </div>

                        <div className="mt-4">
                          <div className="text-md font-semibold text-gray-600 mt-2">
                            Message
                          </div>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="phoneNumber"
                              onChange={formik.handleChange}
                              value={formik.values.message}
                              className="rounded-md w-full shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "></input>
                          </div>
                        </div>
                      </div>
                        {leadSubmitFailed &&
                          <div className="bg-red-600 text-white font-semibold my-2 rounded-sm p-3">
                             Failed to submit request. please try again.
                          </div>
                          }
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onLeadSubmit}>
                    {leadSubmitInProgress ? "Submitting ..." : "Submit"}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={props.closeDialog}
                  ref={cancelButtonRef}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    )
};

export default LeadSubmission;