import "./App.css";
import React from "react";
import {Auth} from "aws-amplify";
import SimpleSnackbar from "./components/shared/SimpleSnackbar";
import {CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { getUsersAction } from "./shared/actions/usersActions";
import { getProjectsAction } from "./shared/actions/projectsActions";
import { getTasksAction, setIsTaskSelectedAction } from "./shared/actions/tasksActions";
import { getUser } from "./shared/graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import AuthContext from './shared/utils/authContext';
import { withAuthenticator } from '@aws-amplify/ui-react'
import Home from './Home';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPending: true,
      isError: false,
      userInfo: {}
    }

    this.getUserInfo = this.getUserInfo.bind(this);
    this.getView = this.getView.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    Auth.currentUserInfo().then((response) => {
      console.log("User data", response);
      if (response) {
        this.getUserInfo(response);
      } else {
        //will be redirected to login screen
      }
    })
  }

  getUserInfo(response) {
    //TODO: get user here or throw alert on error
    this.setState({ isPending: true, isError: false });
  
    API.graphql(
      graphqlOperation(getUser, {
          email: response.attributes.email,
      })
    ).then(response => {
      console.log("saved token");
      console.log(response);
      this.setState({ isPending: false, isError: false, userInfo: response.data.getUser});
      this.props.getUsers(response.data.getUser.clientId);
      this.props.getProjects(response.data.getUser.clientId);

    })
    .catch(err => {
      console.log(err);
      this.setState({ isError: true });
    });
  }

  getView() {
    if (this.state.isPending || this.props.projects.isPending || this.props.users.isPending) {
      return <CircularProgress style={{marginTop:"20%", marginLeft :"50%"}}/>;
    } else if (this.state.isError) {
      return <div> <SimpleSnackbar show={true} message="Failed to fetch data" buttonText="Retry" onClick={this.getData}/></div>;
    } else {
      return <Home authData={this.props.authData} userInfo={this.state.userInfo} isAdmin={this.state.userInfo.isAdmin} />
    }
  }

  render() {
    return (
        <AuthContext.Provider value={{
            userId: this.state.userInfo.userId, 
            email: this.state.userInfo.email,
            clientId: this.state.userInfo.clientId,
            isAdmin: this.state.userInfo.isAdmin,
            role: this.state.userInfo.role,
            name: this.state.userInfo.name ? this.state.userInfo.name: '',
          }}>
              {this.getView()}
        </AuthContext.Provider>
      );
  }
}

function mapStateToProps(state) {
  return {
    tasks: state.tasks,
    userInfo: state.userInfo,
    users: state.users,
    projects: state.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTasks: function(userId, status, inBackground, clientId) {
      dispatch(getTasksAction(userId, status, inBackground, clientId));
    },
    getUsers: function (clientId) {
      dispatch(getUsersAction(clientId))
    },
    getProjects: function (clientId) {
      dispatch(getProjectsAction(clientId))
    },
    setIsTaskSelectedAction: function (leadId, isSelected) {
      dispatch(setIsTaskSelectedAction(leadId, isSelected ))
    }
  };
}

export default withAuthenticator(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));

