import React, { useState, useEffect } from "react";
import PropertyStatusView from '../components/shared/PropertyStatusView';

//TODO: define constants for these, research whats betst way

const PropertiesListView = (props) => {
  console.log(props);
  return (
    <div>
      {props.properties && props.properties.length === 0 ? 
      <div className="text-center font-semibold text-gray-600 mt-5 text-lg"> No results to display </div> : null}
      {props.properties.map(property => {
        return (
          <div key={property.itemId} onClick={()=>props.setPropertyByItemId(property.propertyId)} className="rounded bg-white shadow p-5 my-2 cursor-pointer">
            <div className="flex flex-row justify-between">
              <div className="font-semibold text-gray-600 mt-2">{property.name}</div>
              <div className="flex-end">
                {<PropertyStatusView status={property.status} />}
              </div>
            </div>
          </div>);
      })}
    </div>
  );
};

export default PropertiesListView;
