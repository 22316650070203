import React from 'react';
import ProjectReports from './pages/ProjectReports';
import PropertiesPage from './pages/PropertiesPage';
import RequestsPage from './pages/RequestsPage';
import PublicViewPage from './pages/PublicViewPage';
import UserReports from './pages/UserReports';
import LeadsPage from './pages/LeadsPage';
import AdCreator from './pages/AdCreator';
import "react-awesome-lightbox/build/style.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Sidebar from './components/partials/Sidebar';
import Header from './components/partials/Header';
import WelcomeBanner from './components/partials/dashboard/WelcomeBanner';
import SalesPage from './pages/SalesPage';
//http://localhost:1313/?viewType=publicLayout&projectId=23456
function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default function Home() {
  const [title, setTitle] = React.useState("Tasks");

  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  //TODO: move this to index.js to remove auth once you have the public api ready
  console.log("location", window.location);

  //http://localhost:1313?viewType=vishita
  if (getParameterByName("viewType") === "vishita") {
    return <PublicViewPage /> 
  }

  if (getParameterByName("viewType") === "publicLayout" && getParameterByName("projectId")) {
    return <PropertiesPage isPublic={true} projectId={getParameterByName("projectId")}/>
  }

  return (
  <Router>
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <Switch>
                <Route path="/tasks">
                  <Orders />
                </Route>
                <Route path="/leads">
                  <LeadsPage />
                </Route>
                <Route path="/projectReports">
                  <ProjectReports />
                </Route>
                <Route path="/userReports">
                  <UserReports />
                </Route>
                <Route path="/properties">
                  <PropertiesPage />
                </Route>
                <Route path="/requests">
                  <RequestsPage />
                </Route>
                <Route path="/sales">
                  <SalesPage />
                </Route>
                <Route path="/">
                  <PropertiesPage />
                </Route>
              </Switch>
            </div>
          </main>
      </div>
      </div>
    </Router>
  );
}

function Orders() {
  return <h2>Orders</h2>;
}


function Topics() {
  return <h2>Topics</h2>;
}
