import React, { useState, useContext, useEffect } from "react";
import PropertyStatusView from '../components/shared/PropertyStatusView';
import Lightbox from "react-awesome-lightbox";
import { useFormik } from 'formik';
import { API, graphqlOperation } from 'aws-amplify';
import {
  updateProperty,
  deleteStatusChangeRequest
} from '../shared/graphql/mutations';
import * as yup from 'yup';
import AuthContext from '../shared/utils/authContext';
import RequestToBook from "./detailView/RequestToBook";
import RequestToHold from "./detailView/RequestToHold";
import RequestView from "./detailView/RequestView";
import LeadSubmission from "./detailView/LeadSubmission";
import { useDispatch } from 'react-redux';
import { deleteRequestAction } from '../shared/actions/requestsActions';

const validationSchema = yup.object({
  status: yup
    .string('Set a status')
    .required('is required'),
  dimensions: yup
    .string('Enter your dimenstions')
    .required('Cannot be empty'),
  size: yup
    .number('Should be a number')
    .required('Cannot be empty'),
  currentPrice: yup
    .number('Should be a number')
    .required('Cannot be empty')
});

const PropertyDetailView = (props) => {
  const userInfo = useContext(AuthContext);
  console.log("userInfo context ", userInfo);
  let property = props.property;
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [showRequestToHold, setShowRequestToHold] = useState(false);
  const [showRequestToBook, setShowRequestToBook] = useState(false);
  const [deletRequestInProgress, setDeletRequestInProgress] = useState(false);
  const [showLeadSubmitDialog, setShowLeadSubmitDialog] = useState(false);
 
  const [showLayoutImage, setShowLayoutImage] = useState(false);
  console.log("PropertyDetailView props", props.requests);
  const updatePropertyOnServer = (updatedData) => {
    setIsSaving(true);
    API.graphql(graphqlOperation(updateProperty
      , {
        input: {
          clientId: props.property.clientId,
          projectId: props.property.projectId,
          propertyId: props.property.propertyId,
          groupId: props.property.groupId,
          status: updatedData.status,
          description: updatedData.description,
          size: updatedData.size,
          dimensions: updatedData.dimensions,
          currentPrice: updatedData.currentPrice,
          //layoutImageURL: updatedData.layoutImageURL,
          updated: Math.floor(Date.now() / 1000)
        }
      })).then((response) => {
        //TODO: there can be errors in here too, you need to check response.errors and show error message
        console.log("updateProperty", response);
        setIsSaving(false);
        setIsEditMode(false);
        props.updateProperty(props.property.propertyId, response.data.updateProperty);
      }).catch((err) => {
        //TODO: show error how ?
        console.warn(err);
      });
  };

  const cancelStatusChangeRequest = (requestId) => {
    setDeletRequestInProgress(true);
    API.graphql(graphqlOperation(
      deleteStatusChangeRequest, {
        input: {
          projectId: props.property.projectId,
          requestId: requestId,
        },
        condition: {
          requestedBy:{eq: userInfo.userId}
        }
    })).then((response) => {
      setDeletRequestInProgress(false);
      console.log("cancelStatusChangeRequest", response);
      dispatch(deleteRequestAction(requestId));
    }).catch((err) => {
      setDeletRequestInProgress(false);
      //TODO: erro handling
      alert("Cancel request failed, please try again");
      console.warn(err);
    });
  } 

  const formik = useFormik({
    initialValues: {
      status: property.status,
      description: property.description,
      size: property.size,
      dimensions: property.dimensions,
      currentPrice: property.currentPrice,
      layoutImageURL: property.layoutImageURL
    },
    enableReinitialize: true,
    //validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      updatePropertyOnServer(values);
    },
  });
  
  property.layoutImageURL = "https://www.roomsketcher.com/wp-content/uploads/2017/11/RoomSketcher-Order-Floor-Plans-2D-Floor-Plan.jpg";

  //TODO: show these errors on each input and on save say fix errors..
  //TODO: show error how ?
  console.log(formik.errors);

  return (
    <div className={"rounded-md mx-4 my-2 bg-white shadow-lg absolute left-0 right-0 md:left-auto md:right-0 md:w-96 z-30"+ (props.isPublic? " top-2": " top-16")}>
    <div className="">
     <form onSubmit={isEditMode ? formik.handleSubmit : null}>
      <div className="flex rounded-md rounded-b-none flex-row-reverse bg-gray-700 p-3 ">
          <div className="flex-end">
            {userInfo.role ==="admin" && props.isPublic !== true && <div className={"inline-block " + (userInfo.role === "admin" ? " " : " hidden")}>
                {isSaving ?
                  <div className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none">
                    Saving...
              </div>
                  : isEditMode ?
                    <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium cursor-pointer srrounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none">
                      {"Save"}
                    </button>
                    :
                    <div onClick={() => setIsEditMode(!isEditMode)} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md cursor-pointer text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none">
                      {"Edit"}
                    </div>
                }
              </div>}
          <div onClick={props.closeView} className="inline-flex pointer justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none cursor-pointer">
            Close
          </div>
        </div>
      </div>

      <div className={isEditMode ?"block ":"hidden"}>
        <div className="p-4 ">
          <div className="flex flex-row justify-between">
            <div className="text-2xl font-semibold text-gray-600 mt-2">
              {property.name}
            </div>

            <div className="flex-end">
                <select
                  name="status"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                <option value="AVAILABLE"> Available </option>
                <option value="ON_HOLD"> On Hold </option>
                <option value="SOLD"> Sold </option>
              </select>
            </div>
          </div>

          <div className="mt-4">
            <div className="text-md font-semibold text-gray-600 mt-2">
              Description
            </div>
            <div className="mt-1">
              <input
                type="text"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "></input>
            </div>
          </div>

          <div className="mt-3">
            <div className="text-md font-semibold text-gray-600 mt-2">
              Size
            </div>
            <div className="text-gray-700 mt-1">
              <input
                type="text"
                name="size"
                onChange={formik.handleChange}
                value={formik.values.size}
                className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "></input>
            </div>
          </div>

          <div className="mt-3">
            <div className="text-md font-semibold text-gray-600 mt-2">
              Dimensions
            </div>
            <div className="text-gray-700 mt-1">
              <input
                type="text"
                name="dimensions"
                onChange={formik.handleChange}
                value={formik.values.dimensions}
                className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "></input>
            </div>
          </div>

          <div className="mt-3">
            <div className="text-md font-semibold text-gray-600 mt-2">
              Current Price
            </div>
            <div className="text-gray-700 mt-1">
              <input
                type="text"
                name="currentPrice"
                onChange={formik.handleChange}
                value={formik.values.currentPrice}
                className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "></input>
            </div>
          </div>

          <div className="mt-3">
            <div className="text-md font-semibold text-gray-600 mt-2">
              Layout Image URL
            </div>
            <div className="text-gray-700 mt-1">
              <input
                type="text"
                name="layoutImageURL"
                onChange={formik.handleChange}
                value={formik.values.layoutImageURL}
                className="rounded-md shadow-sm border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "></input>
            </div>
          </div>
        </div>
        </div>
      
      <div id="detail-view" className={isEditMode ? "hidden":"block overflow-y-scroll"}>
        <div className="p-4 ">
          <div className="flex flex-row justify-between">
            <div className="text-2xl font-semibold text-gray-600 mt-2">
              {property.name}
            </div>

            <div className="flex-end">
              {<PropertyStatusView status={property.status} />}
            </div>
          </div>
              
          <div className="bg-white">
            <RequestView
              property={props.property}
              userInfo={userInfo}
              onRequestToHoldClick={() => setShowRequestToHold(true)}
              onRequestToBookClick={() => setShowRequestToBook(true)}
              onLeadSubmitClick={() => setShowLeadSubmitDialog(true)}
              onCancelHoldRequestClick={(requestId) => cancelStatusChangeRequest(requestId)}
              onCancelBookRequestClick={(requestId) => cancelStatusChangeRequest(requestId)}
              isPublic={props.isPublic}
              requests={props.requests}
            />
          </div>

          <div className="mt-3">
            <div className="text-md font-semibold text-gray-600 mt-2">
              Description
            </div>
            <div className="text-gray-700 mt-1 pl-2">
              {property.description}
            </div>
          </div>

          <div className="mt-3 flex flex-row justify-between">
            <div className="text-md font-semibold text-gray-600">
              Size
            </div>
            <div className="text-gray-700 pl-2">
              {property.size}  sq.Yard
            </div>
          </div>

          <div className="mt-3 flex flex-row justify-between">
            <div className="text-md font-semibold text-gray-600">
              Dimensions
            </div>
            <div className="text-gray-700 mt-1 pl-2">
              {property.dimensions} 
            </div>
          </div>

          {props.isPublic && <div className="mt-3 flex flex-row justify-between">
            <div className="text-md font-semibold text-gray-600">
              Price
            </div>
            <div className="text-gray-700">
              {property.pricePerUnit} per Sq.Yard
            </div>
          </div>}
        
              {props.isPublic !== true &&
                <div>
                  <div className="mt-3">
                    <div className="text-md font-semibold text-gray-600 mt-2">
                      Price Details
            </div>
                  </div>
              
                  <div className="mt-3 ml-2 flex-row flex justify-between ">
                    <div className="text-md text-gray-600">
                      Price Per Sq yard
              </div>
                    <div className="text-gray-700">
                      {property.pricePerUnit} Rs
              </div>
                  </div>
                  <div className="mt-3 ml-2 flex-row flex justify-between ">
                    <div className="text-md text-gray-600">
                      Premium Charges
              </div>
                    <div className="text-gray-700">
                    </div>
                  </div>
                  <div className="mt-3 ml-6 flex-row flex justify-between ">
                    <div className="text-md text-gray-600">
                      Corner
              </div>
                    <div className="text-gray-700">
                      500 Rs
              </div>
                  </div>
                  <div className="mt-3 ml-6 flex-row flex justify-between ">
                    <div className="text-md text-gray-600">
                      East Facing
              </div>
                    <div className="text-gray-700">
                      300 Rs
              </div>
                  </div>
                  
                  <div className="mt-3 ml-2 flex-row flex justify-between ">
                    <div className="text-md text-gray-600">
                      Total Price Per Sq Yard
              </div>
                    <div className="text-gray-700">
                    {property.pricePerUnit + 800} Rs
              </div>
                  </div>
                  <div className="mt-3 ml-2 flex-row flex justify-between ">
                    <div className="text-md text-gray-600">
                      Plot Size
              </div>
                    <div className="text-gray-700">
                    {property.size} sq Yard
              </div>
                  </div>
                  <div className="mt-3 ml-2 flex-row flex justify-between ">
                    <div className="text-md text-gray-600 font-semibold">
                      Total Price
              </div>
                    <div className="text-gray-700">
                    {property.size *(property.pricePerUnit + 800)} Rs
              </div>
                  </div>

                </div>}

          <div className="mt-3">
            <div className="text-md font-semibold text-gray-600 my-2">
              Layout Image
            </div>

            <img
                alt="layoutImg"
                onClick={() => setShowLayoutImage(true)}
                width="160px"
                height="200px"
                src={property.layoutImageURL} className="mt-2 ml-2 cursor-pointer" />
            
          </div>
              
          </div>
        </div>
    </form>
    </div>
      
      {showLayoutImage ?
        <Lightbox
          image={property.layoutImageURL}
          title="Layout image"
          onClose={() => setShowLayoutImage(false)} /> : null}

      <RequestToHold
        property={props.property}
        isOpen={showRequestToHold}
        closeDialog={() => setShowRequestToHold(false)}
      />

      <RequestToBook
        property={props.property}
        isOpen={showRequestToBook}
        closeDialog={() => setShowRequestToBook(false)}
      />

      <LeadSubmission
        property={props.property}
        isOpen={showLeadSubmitDialog}
        closeDialog={() => setShowLeadSubmitDialog(false)}
        
      />
    </div>
  );
};

export default PropertyDetailView;
