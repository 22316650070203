import PropertyStatusView from '../shared/PropertyStatusView'
import { MOUSE_HOVER } from '@material-ui/data-grid'
import {
  getUserNameFromUserList, getRequestTypeText, getUserEmailFromUserList,
  uuidv4, getTimestampInSecs
} from '../../shared/utils'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useContext, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { API, graphqlOperation } from "aws-amplify";

import { useDispatch } from 'react-redux';
import RequestRejectModal from './RequestRejectModal';
import TimelineView from './TimelineView';
import {
  updateStatusChangeRequest,
  updateProperty,
  createRequestActivity,
  deleteStatusChangeRequest
} from '../../shared/graphql/mutations';
import {
  listRequestActivitys
} from '../../shared/graphql/queries';
import AuthContext from '../../shared/utils/authContext';
import { updateRequestAction, deleteRequestAction } from '../../shared/actions/requestsActions';
import * as config from '../../commons/config';

const createTimelineViewDataForHoldRequest = (request, requestActivityData) => {
  const timeLineData = [];
  //add pending
  if (request.status === "PENDING") {
    timeLineData.push({
      timestamp: null,
      title: "Pending approval"
    })
  }
  for (const requestActivityItem of requestActivityData) {
    //only add request activities for the same requestid
    if (requestActivityItem.requestId === request.requestId) {
      timeLineData.push({
        timestamp: requestActivityItem.createdAt,
        title: config.getRequestActivityTypeStr(requestActivityItem.type),
        description: requestActivityItem.description
      })
    }
  }

  //add creation
  timeLineData.push({
    timestamp: request.createdAt,
    title: "Request to hold created"
  })
  return timeLineData;
}

export default function HoldRequestModal(props) {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null)
  console.log(props)
  const userInfo = useContext(AuthContext);
  const [inProgress, setInProgress] = useState(false);
  const [rejectInProgress, setRejectInProgress] = useState(false);
  const [failed, setFailed] = useState(false);
  const [rejectIsOpen, setRejectIsOpen] = useState(false); //for reject comment box modal
  const [requestActivityInProgress, setRequestActivityInProgress] = useState(false);
  const [requestActivityData, setRequestActivityData] = useState([]);
  const [deletRequestInProgress, setDeletRequestInProgress] = useState(false);

  useEffect(() => {
    if (props.holdOpen) {
      getRequestActivities();
    }
  },[props.holdOpen])

  const updateHoldRequestStatus = () => {
    setInProgress(true);
    API.graphql(graphqlOperation(
      updateStatusChangeRequest, {
        input: {
          clientId: props.request.clientId,
          projectId: props.request.projectId,
          requestId: props.request.requestId,
          status: "ACCEPTED"
        }
    })).then((response) => {
      console.log("updateStatusChangeRequest", response.data.updateStatusChangeRequest);
      addRequestActivity("APPROVED", "Request has been approved by " + userInfo.role);
      updatePropertyStatus(response.data.updateStatusChangeRequest);
    }).catch((err) => {
      //setInProgress(false);
      setFailed(true);
      console.warn(err);
    });
  }

  const updateHoldRejectRequestStatus = (rejectComment) => {
      setRejectInProgress(true);
      setRejectIsOpen(false);
      API.graphql(graphqlOperation(
        updateStatusChangeRequest, {
          input: {
            clientId: props.request.clientId,
            projectId: props.request.projectId,
            requestId: props.request.requestId,
            status: "REJECTED",
            rejectComment: rejectComment
          }
      })).then((response) => {
        console.log("updateStatusChangeRequest", response.data.updateStatusChangeRequest);
        let updatedRequest = response.data.updateStatusChangeRequest;
        dispatch(updateRequestAction(updatedRequest.requestId, updatedRequest));
        addRequestActivity("REJECTED", rejectComment);
        setRejectInProgress(false);
        props.setHoldOpen(false);
      }).catch((err) => {
        //setInProgress(false);
        setFailed(true);
        console.warn(err);
      });
  }
    console.log(userInfo.role);
    //
  const updatePropertyStatus = (updatedRequest)=>{
    API.graphql(graphqlOperation(
      updateProperty, {
        input: {
          clientId: props.request.clientId,
          projectId: props.request.projectId,
          propertyId: props.request.propertyId,
          status: "ON_HOLD",
          assignedTo: updatedRequest.requestedBy
        }
    })).then((response) => {
      setFailed(false);
      console.log("updateProperty", response);
      // props.closeDialog();
      props.setHoldOpen(false);
      dispatch(updateRequestAction(updatedRequest.requestId, updatedRequest))
      
      setInProgress(false);
      
    }).catch((err) => {
      //setInProgress(false);
      setFailed(true);
      console.warn(err);
    });
  }

  const cancelStatusChangeRequest = (requestId) => {
    setDeletRequestInProgress(true);
    API.graphql(graphqlOperation(
      deleteStatusChangeRequest, {
        input: {
          projectId: props.request.projectId,
          requestId: requestId,
        },
        condition: {
          requestedBy:{eq: userInfo.userId}
        }
    })).then((response) => {
      setDeletRequestInProgress(false);
      console.log("cancelStatusChangeRequest", response);
      dispatch(deleteRequestAction(requestId));
      props.setHoldOpen(false);
    }).catch((err) => {
      setDeletRequestInProgress(false);
      //TODO: erro handling
      alert("Cancel request failed, please try again");
      console.warn(err);
    });
  } 


  const getRequestActivities = () => {
    console.log("calling listRequestActivitys ", props.request);
    setRequestActivityInProgress(true);
    API.graphql(graphqlOperation(
      listRequestActivitys, {
        requestId: props.request.requestId,
        sortDirection: "DESC"
    })).then((response) => {
      console.log("listRequestActivitys", response);

      setRequestActivityInProgress(false);
      setRequestActivityData(response.data.listRequestActivitys.items);
      
    }).catch((err) => {
      //TODO: add failure handling
      setRequestActivityInProgress(false);
      console.warn(err);
    });
  }

  const addRequestActivity = (type, description) => {
    console.log("calling addToRequestActivity");
    API.graphql(graphqlOperation(
      createRequestActivity, {
        input: {
          propertyId: props.request.propertyId,
          clientId: props.request.clientId,
          projectId: props.request.projectId,
          requestId: props.request.requestId,
          created: getTimestampInSecs(),
          type: type,
          activityBy: userInfo.userId,
          description: description
        }
    })).then((response) => {
      console.log("addRequestActivity", response);
      //TODO: add to request activity section
    }).catch((err) => {
      //TODO: add failure handling
      console.warn(err);
    });
  }
  
  return (
    <div>
    <Transition.Root show={props.holdOpen} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden" open={props.holdOpen} onClose={rejectIsOpen? ()=>{} : ()=> props.setHoldOpen(false)}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            initialFocus={cancelButtonRef}
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pt-16 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button>
                      {/* <span className="sr-only">Close panel</span> */}
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-2 sm:px-6"><Dialog.Title className="text-2xl font-medium text-gray-900">Request to Hold</Dialog.Title></div>
                  <div onClick={() => props.setHoldOpen(false)} style={{cursor: 'pointer'}}  className="absolute top-8 right-6 hover:text-red-700">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                    {/* self-end px-4 sm:px-6 */}
                  </div>
                  <div className="mt-2">
                      <div className="pl-6 mt-6">
                      <div className="text-md font-semibold text-gray-600 mt-2">
                      Request By
                      </div>
                      <div className="mt-1 rounded-md  border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ">
                      {getUserNameFromUserList(props.usersList, props.request.requestedBy)}
                      </div>
                    </div>

                    <div className="pl-6 mt-6">
                      <div className="text-md font-semibold text-gray-600 mt-2">
                      Property ID
                      </div>
                      <div className="mt-1 rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ">
                      {props.request.propertyId}
                      </div>
                    </div>

                    <div className="pl-6 mt-6">
                      <div className="text-md font-semibold text-gray-600 mt-2">
                      Requested At
                      </div>
                      <div className="mt-1 rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ">
                      {props.request.createdAt}
                      </div>
                    </div>
                      
                    <div className="pl-6 mt-6">
                      <div className="text-md font-semibold text-gray-600 mt-2">
                      Comment
                      </div>
                      <div className="mt-1 rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ">
                      {props.request.requestComment}
                      </div>
                    </div>
                  </div>

                    {/* logic to sort by role and status */}
                    
                    {userInfo.userId === props.request.requestedBy && props.request.status === 'PENDING' &&
                      <div className="w-full pt-3 pl-3">{deletRequestInProgress ? 
                          <button
                            type="button"
                            className="w-full lg:justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            ref={cancelButtonRef}
                          >
                            Cancelling
                          </button> :
                          <button
                          type="button"
                          className="w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={()=>cancelStatusChangeRequest(props.request.requestId)} ref={cancelButtonRef}
                        >
                          Cancel Request
                        </button> }
                      </div>}

                  {userInfo.role !== 'agent' && props.request.status === 'PENDING'? 

                  <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse pt-10">
                    <div>
             {inProgress ?
                            <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Approving
                </button> : <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={updateHoldRequestStatus}
                >
                  Approve Request
                </button>}</div>
                  <div>{rejectInProgress ? 
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                     ref={cancelButtonRef}
                  >
                    Rejecting
                  </button> :
                  <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={()=>setRejectIsOpen(true)} ref={cancelButtonRef}
                >
                  Reject Request
                </button> }</div>  
              </div>
                : null }

                  <div className="px-6">
                      <TimelineView
                        inProgress={requestActivityInProgress}
                        data={createTimelineViewDataForHoldRequest(props.request, requestActivityData)}
                    />
                  </div>

                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        
      </Dialog>
    </Transition.Root>
    
    <RequestRejectModal
          rejectIsOpen={rejectIsOpen}
          setRejectIsOpen={setRejectIsOpen}
          onSubmit={updateHoldRejectRequestStatus}/>
    </div>

  )
}


