import React, { useState, useEffect,useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { connect } from "react-redux";
import HoldRequestModal from '../components/requests/HoldRequestModal';
import BookRequestModal from '../components/requests/BookRequestModal';
import AuthContext from '../shared/utils/authContext';
import {
  listStatusChangeRequests
} from '../shared/graphql/queries';
import {
  createStatusChangeRequest
} from '../shared/graphql/mutations';
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  TextField
} from "@material-ui/core";
import SimpleSnackbar from "../components/shared/SimpleSnackbar";
import * as config from '../commons/config';
import {
  getUserNameFromUserList
} from '../shared/utils'
import { setRequestsAction } from "../shared/actions/requestsActions";


const PENDING_VIEW_TYPE = "PENDING_VIEW_TYPE";
const ACCEPTED_VIEW_TYPE = "ACCEPTED_VIEW_TYPE";
const REJECTED_VIEW_TYPE = "REJECTED_VIEW_TYPE";

const RequestsPage = (props) => {
  useEffect(() => {
    getData();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [requests, setRequests] = useState([]);
  const [holdOpen, setHoldOpen] = useState(false);
  const [bookOpen, setBookOpen] = useState(false);
  const [request, setRequest] = useState({});
  const [viewType, setViewType] = useState(PENDING_VIEW_TYPE);
  const userInfo = useContext(AuthContext);

  const activeViewTypeClassName = "py-2 px-6 shadow bg-white font-medium m-0.5 rounded-lg text-md";
  const inActiveViewTypeClassName = "py-2 px-6 text-md font-medium m-0.5 text-gray-500 bg-gray-200";

  const getData = () => {
    setIsLoading(true);
    API.graphql(graphqlOperation(
      listStatusChangeRequests, {
      projectId: "705a8b9e-dad3-4782-807b-1d99a430378e"
    })).then((response) => {
      console.log("listStatusChangeRequests", response.data.listStatusChangeRequests.items);
      // setProjects = response.data.listStatusChangeRequests.items
      props.setRequests(response.data.listStatusChangeRequests.items);
      setIsLoading(false);

    }).catch((err) => {
      setIsLoading(false);
      setIsError(true);
      console.warn(err);
    });
  }

  const onRequestClick = (request) =>{

    setRequest(request);
    
      if(request.type === 'REQUEST_TO_HOLD'){
        setHoldOpen(true);
        setBookOpen(false);
      } else{
        setHoldOpen(false);
        setBookOpen(true);
      }

  }

  const getRequestStatusView = (type) => {
    if (type === "REQUEST_TO_BOOK") {
      return <span className="text-xs font-semibold inline-block py-2 px-2 uppercase rounded text-green-600 bg-green-100 last:mr-0 mr-1">
        Request to Book
      </span>
    } else if (type === "REQUEST_TO_HOLD") {
      return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-yellow-600 bg-yellow-200 last:mr-0 mr-1">
        Request to Hold
       </span>
    } else if (type === "SOLD") {
      return <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-gray-600 bg-gray-200 last:mr-0 mr-1">
        Sold
       </span>
    }
    else { <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-pink-600 bg-pink-200 last:mr-0 mr-1">
            Unknown
           </span>}
    
  }
  
  console.log("Users list", props.users.data);
  console.log("requests", props.requests);

  if (isLoading) {
    return <CircularProgress style={{marginTop:"20%", marginLeft :"50%"}}/>;
  } else if (isError) {
    return <div> <SimpleSnackbar show={true} message="Failed to fetch data" buttonText="Retry" onClick={getData}/></div>;
  }

  // var notcompletedItems = props.todoItems.filter(function (item) {
  //   return (item.isCompleted === false);
  // });

  //TODO: update these filter logics so request follows pattern based on flow

  var filterByRole = props.requests.filter((request) => {
    if (userInfo.userId === request.requestedBy) {
      return true;
    }
    if (request.type === "REQUEST_TO_BOOK") {
      return config.showThisRequestToUser(request.approvedBy, userInfo.role);
    } else if (request.type === "REQUEST_TO_HOLD"){
      //hold request
      if (userInfo.role === 'admin') {
        return true;
      } else {
        return false;
      }
    }
  });

  var afterStatusFilter = filterByRole.filter((request) => {
    if (viewType === PENDING_VIEW_TYPE) {
      //don't show in pending if already approved by given user role
      if (config.isRequestApprovedByUserRole(request.approvedBy, userInfo.role)) {
        return false;
      }
      return request.status === 'PENDING';//returns boolean
    } else if (viewType === ACCEPTED_VIEW_TYPE) {
      if (config.isRequestApprovedByUserRole(request.approvedBy, userInfo.role)) {
        return true;
      }
      return request.status === 'ACCEPTED';
    } else{
      return request.status === 'REJECTED';
    }
  });


  return (
   <div> 
      <div className={"flex mx-auto pb-4" + (props.isPublic ? " hidden" :"") }>
        <div className="flex mx-auto cursor-pointer shadow rounded-l-lg bg-gray-200 rounded-r-lg">
          <div
            onClick={() => setViewType(PENDING_VIEW_TYPE)}
            className={" " + (viewType === PENDING_VIEW_TYPE ? activeViewTypeClassName : inActiveViewTypeClassName)}>  
            <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
            </svg>
              Pending
          </div>
          <div
            onClick={() => setViewType(ACCEPTED_VIEW_TYPE)}
            className={"" + (viewType === ACCEPTED_VIEW_TYPE ? activeViewTypeClassName : inActiveViewTypeClassName)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"  d="M5 13l4 4L19 7" />
            </svg>
            Approved
          </div>
          <div
            onClick={() => setViewType(REJECTED_VIEW_TYPE)}
            className={"" + (viewType === REJECTED_VIEW_TYPE ? activeViewTypeClassName : inActiveViewTypeClassName)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"  d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
            </svg>
            Rejected
          </div>
        </div>
      </div>

      {afterStatusFilter.length === 0 ? 
         <div className="font-semibold text-lg text-center mt-20"> No Requests to Display</div> : null}

     {afterStatusFilter.map((request)=> {
        return( <div onClick={()=> 
                 onRequestClick(request)} 
          className="rounded bg-white shadow p-3 my-2 cursor-pointer w-full md:w-3/6 mx-auto">
          <div className="flex flex-row justify-between">    
              <div className="flex-column ">
                    <div className="pl-2 mt-2">
                      <div className="flex text-md text-gray-600">
                      Plot No: {request.propertyId}
                      </div>
                      {/* <div className="mt-1 rounded-md  border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ">
                      {request.propertyId}
                      </div> */}
                    </div>
                    <div className="pl-2 mt-2">
                      <div className="flex text-md  text-gray-600 mt-2">
                      Requested By: {getUserNameFromUserList(props.users.data, request.requestedBy)}
                      </div>
                    </div>
              </div>
              <div className="mt-4 hover:text-purple-700">
                {getRequestStatusView(request.type)}
              </div>
            </div>
          </div>);
    })}

      <HoldRequestModal
        setHoldOpen={setHoldOpen}
        holdOpen={holdOpen}
        request={request}
        usersList={props.users.data}
        viewType={viewType}
      />
            <BookRequestModal 
                setBookOpen={setBookOpen}
                bookOpen={bookOpen}
                request={request}
                usersList={props.users.data}
                viewType={viewType}
                />


   </div>
  );
};

function mapStateToProps(state) {
  return {
    users: state.users,
    requests: state.requests.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRequests: function (data) {
      dispatch(setRequestsAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestsPage);


